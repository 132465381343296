<template>
  
    <div style=" position: absolute;
    height: 70px;
    bottom: -25px;
    z-index: 1000;
    left: 40px;
    width:80%">
    <v-row>

    <v-col cols="4" style="font-size: 15px!important; text-align: left; line-height:1 " v-html="pied1()"></v-col>
    <v-col cols="4" style="font-size: 15px!important; text-align: center; line-height:1 "  v-html="pied2()"></v-col>
    <v-col cols="4" style="font-size: 15px!important;text-align: right; line-height:1 "  v-html="pied3()"></v-col>

    </v-row>
   </div>

</template>
<script setup>
</script>   
<script>
export default {
props: ['user'],

methods: {
    pied1(){
             if(this.user && this.user.pied1) return  this.user.pied1.replace(/(?:\r\n|\r|\n)/g, '<br>');
             return ""
          },
          pied2(){
            if(this.user && this.user.pied2)  return this.user.pied2.replace(/(?:\r\n|\r|\n)/g, '<br>');
            return ""
          },
          pied3(){
            if(this.user && this.user.pied3)   return this.user.pied3.replace(/(?:\r\n|\r|\n)/g, '<br>');
            return ""
          },

}
}
</script>