<template>
    <div style="padding-top:8px" >

    <div style="   float: left; margin-top:15px"> 
        <img   :src="logoPath"   style="height:100px"  alt="logo">    
    </div>
        <div style="float: right; font-size:20px; margin-top:15px;text-align : right;"> 
                <div style="margin:2px;height:20px;">{{nomComplet(client)}} </div>
                <div style="margin:2px;height:20px;"> {{calculateAge(client.naissance,dateetude)}} ans, né(e) le {{ formatedDate(client.naissance) }} </div>
                <div style="margin:2px;height:20px;"> Date de l'étude : {{ formatedDate(dateetude)}} </div>
                <div style="margin:2px;height:20px;">  J.P. : <span :class="[ jourPersonnel.couleur ]">{{jourPersonnel.valeur}}</span> - M.P. : <span :class="[ moisPersonnel.couleur ]">{{moisPersonnel.valeur}}</span> - A.P. : <span :class="[ anneePersonnelle.couleur ]">{{anneePersonnelle.valeur}}</span> </div>
        </div>
</div>
</template> 
<script setup>

</script>
    <script>
    export default {
    props: ['logoPath', 'dateetude', 'client','jourPersonnel','moisPersonnel','anneePersonnelle', ],

methods: {
    nomComplet(client) {
        if(client.nom  != client.nomNaissance )
            return client.nom +" né.e "+client.nomNaissance+" "+client.prenoms[0]+" "+client.prenoms[1]+" "+client.prenoms[2]+" "+client.prenoms[3]+" "+client.prenoms[4]+" "+client.prenoms[5]
        else
            return client.nom +" "+client.prenoms[0]+" "+client.prenoms[1]+" "+client.prenoms[2]+" "+client.prenoms[3]+" "+client.prenoms[4]+" "+client.prenoms[5]
    },
    formatedDate(date) {
        return new Date(date).toLocaleDateString('fr-FR');
    },
    calculateAge(birthday,dateEtude) { // birthday is a date
    var ageDifMs = (new Date(dateEtude)) - (new Date(birthday).getTime());
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}
}
}
</script>