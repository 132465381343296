import apiService from './apiService.js'


export default {
 
  async getMyMessages() {

      return   apiService.get('messages/');
    },
    
    async  add(aMessage) {
      return   apiService.post('messages/', aMessage);
    },
    async get(msgId) {
      return   apiService.get('messages/'+msgId);
    },
    async delete(msgId) {
      return   apiService.delete('messages/'+msgId);
    },
    async read(msgId) {
      return   apiService.post('messages/'+msgId+'/Read');
    },

    async getUnreadMessages() {

          return   apiService.get('messages/Unreads');
        },
    async saveAccueil( acceuil)
        {
        return   apiService.post('Accueil/', {Valeur:acceuil});   
        },
    async saveNews( news)
        {
          return   apiService.post('News/', {Valeur:news});   
          },
    async getAccueil() {
          return   apiService.get('Accueil/');
        },
    async getNews() {
          return   apiService.get('News/');
        },

        async getCGU() {
          return   apiService.get('CGU/');
        },

        async getLinkTutorial() {
          return   apiService.get('LinkTutorial/');
        },
        async getContact() {
          return   apiService.get('Contact/');
        },
        
        
}